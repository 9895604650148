import { ReactComponent as BtnCross12 } from './btn-cross-12.svg';
import { ReactComponent as BtnCross18 } from './btn-cross-18.svg';
import { ReactComponent as BtnCross24 } from './btn-cross-24.svg';
import { ReactComponent as FoldingArrow12 } from './folding-arrow-12.svg';
import { ReactComponent as FoldingArrow18 } from './folding-arrow-18.svg';
import { ReactComponent as FoldingArrow24 } from './folding-arrow-24.svg';
import { ReactComponent as Accept12 } from './accept-12.svg';
import { ReactComponent as Accept18 } from './accept-18.svg';
import { ReactComponent as Accept24 } from './accept-24.svg';
import { ReactComponent as Search12 } from './search-12.svg';
import { ReactComponent as Search18 } from './search-18.svg';
import { ReactComponent as Search24 } from './search-24.svg';
import { ReactComponent as Calendar12 } from './calendar-12.svg';
import { ReactComponent as Calendar18 } from './calendar-18.svg';
import { ReactComponent as Calendar24 } from './calendar-24.svg';
import { ReactComponent as Info12 } from './info-12.svg';
import { ReactComponent as Info18 } from './info-18.svg';
import { ReactComponent as Info24 } from './info-24.svg';

export const systemIcons: { [key: string]: any } = {
    18: {
        clear: BtnCross12,
        foldingArrow: FoldingArrow12,
        accept: Accept12,
        search: Search12,
        calendar: Calendar12,
        info: Info12,
    },
    24: {
        clear: BtnCross12,
        foldingArrow: FoldingArrow12,
        accept: Accept12,
        search: Search12,
        calendar: Calendar12,
        info: Info12,
    },
    30: {
        clear: BtnCross12,
        foldingArrow: FoldingArrow12,
        accept: Accept12,
        search: Search12,
        calendar: Calendar12,
        info: Info12,
    },
    36: {
        clear: BtnCross18,
        foldingArrow: FoldingArrow18,
        accept: Accept18,
        search: Search18,
        calendar: Calendar18,
        info: Info18,
    },
    42: {
        clear: BtnCross18,
        foldingArrow: FoldingArrow18,
        accept: Accept18,
        search: Search18,
        calendar: Calendar18,
        info: Info18,
    },
    48: {
        clear: BtnCross24,
        foldingArrow: FoldingArrow24,
        accept: Accept24,
        search: Search24,
        calendar: Calendar24,
        info: Info24,
    },
    60: {
        clear: BtnCross24,
        foldingArrow: FoldingArrow24,
        accept: Accept24,
        search: Search24,
        calendar: Calendar24,
        info: Info24,
    },
    none: {
        clear: BtnCross18,
        foldingArrow: FoldingArrow18,
        accept: Accept18,
        search: Search18,
        calendar: Calendar18,
        info: Info18,
    },
};
